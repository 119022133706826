import { Box  } from "@mui/material";
import Home from './components/Home';
import Faq from "./components/Faq.jsx";
import Navbar from "./components/Navbar";
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Box
      backgroundColor='#0f111f'
      >
      <Navbar />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      </Box>
  );
}

export default App;
