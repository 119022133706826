import React, { useState } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';



const faqData = [
    {
        title: "What is Bull Rush and what is its mission?",
        desc: "At its core, Bull Rush is a web3 project with a focus on charity. Establishing a fun, friendly, and competitive community while combining elements of fantasy football and Role Playing Games (RPGs) to offer prizes is a primary focus.Our mission is to expand charitable impact across the globe. We are leveraging the popularity of American football currently, as well as the intent to expand the sport globally coupled with the impact American football athletes have already established.",
    },
    {
        title: "How does Bull Rush combine charity, sports, art, and technology?",
        desc: (
            <span>
              Charity is at the core of what we do. We've established a system to generate recurring charity opportunities that are a combination of sponsor picks and consumer picks. At the foundation of our game, we are leveraging fantasy football points. The art randomly generated on top during the 'minting' process will add modifiers to this score. Use these pieces together and play the game to win!{' '}
              <a href="https://www.youtube.com/watch?v=b5-iJUuPWis" target="_blank" rel="noopener noreferrer">
                Watch the video
              </a>
              {' '}And heck, you can even WIN by NOT PLAYING (more to come!) As we evolve, we have a plan to also implement a completely optional RPG game which will simulate teams playing against each other! We are eventually aiming toward seeing these pieces play in the Metaverse and are working on methods to allow you to watch for additional bonuses!
            </span>
          ),
    },
    {
        title: "How do the NFL player NFTs work on Bull Rush's platform?",
        desc: "We've adopted a team + position approach so specific players never age out in our system. We will take the total score of the particular position and use that as our base score.",
    },
    {
      title: "Do you have an NFL license?",
      desc: "Due to using the statistics and applying our own scoring methods, we do not require an NFL license. We hope to establish a better relationship with the organization over time where we will apply for a license."
    },
    {
        title: "What happens to my NFT and the associated charity donations if the NFL player retires or changes teams?",
        desc: "That’s the beauty of our design! Someone else on the team will step into that spot and you will get their credit! Our intent was to allow the pieces to be eternal on the block chain and this allows us to have no expiration on the pieces.",
    },
    {
        title: "How does Bull Rush merge elements of fantasy football, RPG gaming, and art through NFTs?",
        desc:"We've adopted a team + position approach so specific players never age out in our system. We will take the total score of the particular position and use that as our base score.",
    },
    {
        title: "How can I purchase an NFL player NFT from Bull Rush?",
        desc: "Pieces will be purchasable (minted) via Polygon or CC equivalent on our webpage. The cost will be .02 (ethereum) for a single piece & .05 for a set of 5 pieces."
    },
    {
        title: "How do the recurring donation opportunities work on BullRush?",
        desc: "Our business model allows for a charity pool to cointinually acrrue. This charity pool will be divvied out to each tournament and explicitly stated the value amount for donation.",
    },
    {
        title: "Can I suggest charities to be included in BullRush's ecosystem?",
        desc: "PLEASE DO! While our focus is to have tournament sponsors by athletes and their charity preferences, we want to include everyone's favorite charity at some point!",
    },
    {
        title: "Can I track the impact of my charity donations made through BullRush?",
        desc: "Absolutely! As a business, we need to track this for ourselves. We will be able to show you how much impact you've had through your play!",
    },
    {
        title: "How are the charities selected and how are the funds distributed?",
        desc: "Charities will be a combination of Athlete selections & consumer suggestions. We will evaluate as a community if a charity should be considered. Funds will be allocated to them based upon the charity's preferred method while coaching them how to join the web3 space!",
    },
    {
        title: "How do prizes work in the BullRush gaming element? Can anyone win?",
        desc: "Anyone playing can win! Heck, you can even win by not playing! More on this in our white paper see : Lending",
    },
    {
        title: "Can I still profit from trading or selling my NFTs on BullRush?",
        desc: "Our plan is designed with everyone in mind. Our hopes are that you benefit. Unfortunately, that's a promise we cannot make. We believe we've considered all scenarios and have put forth an opportunity so everyone can win. Remember, our first focus is charity in Bull Rush.",
    },
    {
        title: "What connection does my NFT have with the real-life performance of the NFL player?",
        desc: "We call it the 'heart and soul' of the piece. This is the heartbeat of how a piece works. There is a direct correlation to how players perform in particular position & team to how they will perform in both our tournament fantasy format and our RPG format. This will not be a 1:1 correlation on any piece, meaning the features minted and the amount of times played add additional bonuses on top of the fantasy points.",
    },
    {
        title: "How does BullRush ensure the authenticity and uniqueness of each NFT?",
        desc: "The contract will be available for review before we open to mint. We have incorporated our own randomization and are not pre-minting. With the amount of varying features available, there are well over 1 million possible combinations. Unfortunately, we don’t have that many to mint and are not expecting any duplicates.",
    },
    {
        title: "How are the NFL players involved in BullRush's initiatives?",
        desc: "We are working to add NFL athletes (and athletes in general) to tournament names for consumer entry through a type of “sponsorship.” The charities electable for the tournament winner(s) will be hand selected by the athlete.",
    },
    {
        title: "Are the donations made through BullRush tax-deductible?",
        desc: "Our hopes are to tie the charity donations to the individual winner(s). There is more work to be done on this process as a legal entity, and would require KYC for individuals wanting to take advantage.",
    },
    
]


const Faq = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <Box sx={{ backgroundColor: '#0f111f', padding: '40px' }}>
      {/* Frequently asked questions title */}
      <Typography color="primary" textAlign="center" variant="h3" sx={{ marginBottom: '20px' }}>
        Frequently asked questions
      </Typography>
      {/* FAQ items container */}
      <Box
        sx={{
          display: 'grid',
          gap: '20px',
          gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
        }}
      >
        {/* Map through FAQ data and render each item */}
        {faqData.map((item, i) => (
          <Box key={i} sx={{ display: 'grid', gap: '8px' }}>
            {/* FAQ item button */}
            <Button
              onClick={() => toggle(i)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                backgroundColor: selected === i ? '#15172a' : '#22253d',
                borderRadius: '8px',
                padding: '16px',
                boxShadow: selected === i ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                transition: 'background-color 0.3s, box-shadow 0.3s',
                '&:hover': {
                  backgroundColor: '#15172a',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              {/* FAQ item title */}
              <Typography variant="subtitle1" color="white" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                {item.title}
              </Typography>
              {/* FAQ item toggle symbol */}
              <Typography color="white" fontWeight='bold'>{selected === i ? '-' : '+'}</Typography>
            </Button>
            {/* Show item description if selected */}
            {selected === i && (
              <Typography variant="body1" textAlign="center" color="white" sx={{ marginTop: '16px' }}>
                {item.desc}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
  
};




export default Faq;