import React, { useState } from 'react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import BullRush from '../utils/images/BullRush.png';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ background: 'transparent', boxShadow: 'none' }}>
      <Toolbar style={{ background: '#0f111f' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Logo to link to Homepage */}
          <Link to="/">
            <img src={BullRush} alt="BullRush Logo" style={{ width: '50px', marginRight: '10px' }} />
          </Link>

          {/* Menu Icon for Mobile */}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            aria-controls="mobile-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            sx={{ display: { xs: 'flex', sm: 'none' }, position: 'absolute', bottom: '8px', right: '8px' }}
          >
            <MenuIcon />
          </IconButton>

          {/* Mobile Menu */}
          <Menu
            id="mobile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                HOME
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link to="/faq" style={{ color: 'inherit', textDecoration: 'none' }}>
                FAQ
              </Link>
            </MenuItem>
            {/* Add more menu items for other links */}
          </Menu>

          {/* Link to FAQ Page for Desktop */}
          <Typography variant="h6" component="div" sx={{ display: { xs: 'none', sm: 'block' }, textAlign: 'right' }}>
            New Features Coming Soon
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
              HOME
            </Link>
            <span style={{ margin: '0 8px', color: 'white' }}>|</span>
            <Link to="/faq" style={{ color: 'white', textDecoration: 'none' }}>
              FAQ
            </Link>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
