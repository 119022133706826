import React from 'react'
import Bullrush from '../utils/images/BullRush.png'
import MetaIcon from '../utils/images/MetaIcon.png'
import TwitterIcon from '@mui/icons-material/Twitter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { Box, Typography, CardMedia } from "@mui/material"
import { Stack } from '@mui/system'

import { IconButton, Avatar } from '@mui/material'



const Home = () => {


    return (
        <Box 
            justifyContent="center"
            alignItems="center"
            display="flex"
            m= 'auto'>

            <Stack 
                direction={{ xs: 'column', lg: 'row'}}
                mt={{xs: 10, lg: 15}}
                spacing={{xs: 5, lg: 7 }}
                justifyContent="center"
                alignItems="center"
                display="flex">


                    <Stack 
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                        padding='10px'
                    >

                    <CardMedia
                        component="img"
                        display="flex"
                        
                        sx={{
                            height: 125,
                            width: 150,
                            maxHeight: { xs: 50, md: 125 },
                            maxWidth: { xs: 75, md: 150 },
                            justifyContent: 'center',
                            m: { xs: 'auto' },
                        
                        }}
                            src={MetaIcon} 
                    />
                            <Typography 
                                variant='h3' 
                                color='white'
                                fontFamily="Emotion"
                                noWrap
                                xs={{
                                    justifyContent: 'center',
                                    m: { xs: 'auto' }
                                }}
                            >

                                Coming Soon!
                            </Typography>

                        
                            <Typography 
                                variant='h2' 
                                color='white'
                                fontFamily="Emotion"
                                noWrap
                                sx={{
                                    justifyItems:'center'
                                }}
                            > 
                                    8/x/2023
                            </Typography>

                    </Stack>

                    <CardMedia
                        component="img"
                        display="flex"
                        
                        sx={{
                            height: 702,
                            width: 861,
                            maxHeight: { xs: 250, lg: 702 },
                            maxWidth: { xs: 350, lg: 861 },
                            justifyContent: 'center',
                            m: { xs: 'auto' }
                        }}

                            src={Bullrush}/>




                  
                        <Stack 
                            direction={{xs: 'row', lg: 'column'}}
                            justifyContent="center"
                            alignItems="center"
                            spacing={5}
                            >        

                            <IconButton href='https://twitter.com/BullRushFB' target="_blank" >
                                <Avatar
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        color:"white",
                                        backgroundColor:"transparent", 
                                        ":hover": { 
                                            color: 'blue'
                                        }

                                    }}>
                                    <TwitterIcon
                                        sx={{ fontSize: 100 }}/>
                                </Avatar>
                            </IconButton>

                            <IconButton href='https://discord.gg/WJu2fmfBxA' target="_blank">
                                <Avatar
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        color:"white",
                                        backgroundColor:"transparent",
                                        ":hover": { 
                                            color: 'blue'
                                        }
                                    }}>
                                    <FontAwesomeIcon 
                                        sx={{
                                            fontSize: 100
                                        }}
                                        icon={faDiscord} 
                                        fontSize={100}/>
                                </Avatar>
                            </IconButton>
                                
                        </Stack>

                </Stack>
           </Box>
        )
}

export default Home;